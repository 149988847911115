import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import stick from "../../images/stick.webp"
import perovskite from "../../images/PerovskiteStartupPrize.jpg"
import "../../components/prizes.css"
// images
import { StaticImage } from "gatsby-plugin-image"

const Perov = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">Perovskite Startup Prize</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The American-Made Perovskite Startup Prize is a $3 million prize
                competition designed to accelerate the growth of the U.S.
                perovskite industry and support the rapid development of solar
                cells and modules that use perovskite materials. Through two
                contests, established perovskite researchers will launch a
                company and move toward commercialization in under a year.
              </p>
              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/perovskiteprize">
                    <button type="button" className="btns">
                      Join the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="eager" />
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <h1 className="mac_h1">Supported by a Network of Accelerators</h1>
          <p className="pcol-fz">
            As part of the American-Made Challenges series, the Perovskite
            Startup Prize unites the world’s best-in-class research base with
            the unparalleled entrepreneurial support system of the American-Made
            Network. Consisting of pioneering maker spaces, dozens of energy
            incubators, universities, and 17 U.S. Department of Energy national
            laboratories, the Network is primed to assist entrepreneurs in
            bringing perovskite-focused concepts to market.
          </p>
          <h1 className="mac_h1">The Prize Structure</h1>
          <div>
            <div className="row rww">
              <div className="col">
                <p className="pcol-fz">
                  The Perovskite Startup Prize consists of two consecutive
                  contests that accelerate efforts to develop new, innovative
                  perovskite solar companies with the potential to manufacture
                  commercially viable products in the United States. The
                  best-performing competitors in the Countdown Contest are
                  awarded with cash prizes and are eligible to compete in the
                  Liftoff Contest. This cycle of contests repeats until 2-3
                  final winners are selected for successfully completing the
                  challenge.
                </p>
                <button type="button" className="btns" disabled>
                  Learn about competing in the E-ROBOT Prize{" "}
                </button>
              </div>
              <div className="col">
                <StaticImage
                  src="../../images/PerovskiteStartupPrize.jpg"
                  className="img-styl"
                  alt="perovski startup prize"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <div>
            <h1 className="mac_h1">Important Dates</h1>
            <hr />
            <h2> Phase 1</h2>
            <p className="pcol-fz">
              The Perovskite Startup Prize was announced in March 2021. The
              first deadline to compete in the Countdown Contest is June 30,
              2021. Each contest has a set amount of funding allocated for
              winning submissions. Finalists and winners will be chosen upon
              merit, and contests will be open on a quarterly basis until all
              prize money has been won.
            </p>
            <br />
            <p className="pcol-fz">
              For a full timeline of deadlines and announcements, please vist{" "}
              <a href="https://www.herox.com/perovskiteprize">HeroX</a>, the
              official competition platform.
            </p>
          </div>
          <hr />
        </div>
        <div className="container">
          <h3 className="h3ps mac_h1">
            {" "}
            Solutions that help shape the future{" "}
          </h3>
          <p className="hps">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/perovskiteprize">
              <button type="button" class="btns">
                Accept the Challenge?
              </button>
            </a>
          </div>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Perov
